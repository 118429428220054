<template>
  <div v-if="!$_loading_state">
    <div class="c-section c-section--mb32">
      <h1 class="c-title2 c-title2--mb16">機種変更を検討している方へ</h1>
      <p>
        機種変更をする場合は、機種変更前の端末で以下の引継コードをコピーしてください。
      </p>
    </div>

    <div class="c-section">
      <h3 class="c-title6 c-title6--mb8">引継コード</h3>
      <div class="p-takeover__code">{{ code }}</div>
      <Button
        style-type="quaternary"
        action
        fluid
        squared
        bordered
        flat
        class="p-takeover__copyButton"
        @click="copyCode">
        <template v-slot:iconPrepend>
          <Icon name="copy" />
        </template>
        引継コードをコピーする
      </Button>
    </div>
  </div>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';

export default {
  data: () => ({
    code: null
  }),

  created() {
    this.generateTakeoverCode();
  },

  methods: {
    async generateTakeoverCode() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('customer').getTakeoverCode();
        this.code = data.take_over_code;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    async copyCode() {
      await this.$utilities.copy(this.code);

      window.location.href = this.$systemDialog(
        SystemDialogMessage.TAKEOVER_CODE_COPY
      );
    }
  }
};
</script>
