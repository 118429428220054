var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$_loading_state
    ? _c("div", [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "c-section" },
          [
            _c("h3", { staticClass: "c-title6 c-title6--mb8" }, [
              _vm._v("引継コード"),
            ]),
            _c("div", { staticClass: "p-takeover__code" }, [
              _vm._v(_vm._s(_vm.code)),
            ]),
            _c(
              "Button",
              {
                staticClass: "p-takeover__copyButton",
                attrs: {
                  "style-type": "quaternary",
                  action: "",
                  fluid: "",
                  squared: "",
                  bordered: "",
                  flat: "",
                },
                on: { click: _vm.copyCode },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "iconPrepend",
                      fn: function () {
                        return [_c("Icon", { attrs: { name: "copy" } })]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3034426366
                ),
              },
              [_vm._v(" 引継コードをコピーする ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-section c-section--mb32" }, [
      _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
        _vm._v("機種変更を検討している方へ"),
      ]),
      _c("p", [
        _vm._v(
          " 機種変更をする場合は、機種変更前の端末で以下の引継コードをコピーしてください。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }